.set-rating-container {
    width: 300px;
    min-width: 300px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 12px 0px;
    gap: 4px;
}

.set-rating-container > .rating {
    display: flex;
}

.set-rating-container > .rating-line {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2px;

    width: 100%;
    height: 60px;
}

.set-rating-container > .rating-line > .rating-line-element {
    width: 24px;
    height: 24px;

    background: rgba(175, 175, 175, 0.5);
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.05);
    border-radius: 6px;

    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;

    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    color: #FFFFFF;
    cursor: pointer;
    transition: all 0.08s ease-in-out;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.set-rating-container > .rating-line > .good:hover {
    background: #57C74E;
}

.set-rating-container > .rating-line > .middle:hover {
    background: #FFAA1C;
}

.set-rating-container > .rating-line > .bad:hover {
    background: #F95724;
}

.set-rating-container > .rating-line > .kal:hover {
    background: #222222;
}

.set-rating-container > .rating-line > .active {
    width: 42px;
    height: 42px;

    box-shadow: 0px 8px 24px -6px rgba(0, 0, 0, 0.25);
    border-radius: 12px;

    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;

    display: flex;
    align-items: center;
    text-align: center;

    color: #FFFFFF;
}

.set-rating-container > .rating-line > .good.active {
    background: #57C74E;
}

.set-rating-container > .rating-line > .middle.active {
    background: #FFAA1C;
}

.set-rating-container > .rating-line > .bad.active {
    background: #F95724;
}

.set-rating-container > .rating-line > .kal.active {
    background: #222222;
}

.set-rating-container > .rating-line > .rating-line-element:hover {
    /*width: 42px;*/
    /*height: 42px;*/

    /*box-shadow: 0px 8px 24px -6px rgba(0, 0, 0, 0.25);*/
    /*border-radius: 12px;*/
}

.set-rating-container > .label {
    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;

    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
}

.set-rating-container > .good {
    color: #57C74E;
}

.set-rating-container > .middle {
    color: #FFAA1C;
}

.set-rating-container > .bad {
    color: #F95724;
}

.set-rating-container > .kal {
    color: #222222;
}

@media (min-width: 1368px) {
    .set-rating-container {
        transform: scale(1.15);
    }
}