.score-component {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: flex-start;
    padding: 0px;
    gap: 12px;
}

.score-component > .score {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 3px 4px 2px;
    gap: 2px;
    border-radius: 6px;
    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 12px;
    color: #FFFFFF;
    box-sizing: border-box;
}

.score-component > .empty {
    background: #747474;
}

.score-component > .bad {
    background: #FF551C;
}

.score-component > .middle {
    background: #FFAA1C;
}

.score-component > .good {
    background: #57C74E;
}

.score-component > .reviews {
    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;

    color: #C1C1C1;
}

.score-component > .big {
    width: 38px;
    height: 38px;

    border-radius: 12px;

    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;

    display: flex;
    align-items: center;
    text-align: center;

    color: #FFFFFF;
}