.navigation-container {
    display: flex;
    flex-flow: row;

    position: fixed;
    bottom: 48px;
    left: 50%;
    transform: translateX(-50%);
    gap: 12px;

    z-index: 999;
}

.navigation {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px;

    background: rgba(0, 0, 0, 0.55);
    box-shadow: 0px -6px 6px rgba(0, 0, 0, 0.01), 0px 12px 26px rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(5px);
    border-radius: 50px;

    z-index: 99;
}

.navigation > .navigation-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;

    padding: 18px 20px;
    gap: 12px;

    border-radius: 49px;

    transition: all 0.2s ease-in-out;

    min-width: 74px;
    box-sizing: border-box;

    cursor: pointer;
}

.navigation > .navigation-button > svg {
    color: #FFFFFF;
    fill: #FFFFFF;
    transition: all 0.2s ease-in-out;
}

.navigation > .navigation-button > svg > path {
    color: #FFFFFF;
    fill: #FFFFFF;
    transition: all 0.2s ease-in-out;
}

.navigation > .navigation-button:hover {
    background: rgba(255, 255, 255, 0.2);
}

.navigation > .navigation-button:hover > svg {
    color: #ffffff;
    fill: #ffffff;
}

.navigation > .navigation-button:hover > svg > path {
    color: #ffffff;
    fill: #ffffff;
}

.navigation > .active {
    background: #FFFFFF !important;
}

.navigation > .active > svg {
    color: #000000 !important;
    fill: #000000 !important;
}

.navigation > .active > svg > path {
    color: #000000 !important;
    fill: #000000 !important;
}

@media (max-width: 768px) {
    .navigation-container  {
        bottom: 12px;
    }

    .navigation-back-button-container {
        position: fixed;
        bottom: 72px;
        left: calc(50% + 54px);
        transform: translateX(-50%);
        gap: 12px;

        z-index: 999;
    }

    .navigation-back-button-container > .navigation-button {
        padding: 4px 20px;
    }
}