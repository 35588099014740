.review-component {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 12px;
    gap: 12px;

    width: 100%;

    border-radius: 12px;
    box-sizing: border-box;
}

.review-component > .user {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;

    width: 100%;
}

.review-component > .user > .avatar {
    width: 30px;
    height: 30px;

    border-radius: 30px;
    overflow: hidden;
}

.review-component > .user > .avatar > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.review-component > .user > .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0px;
}

.review-component > .user > .content > .name {
    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    color: #222222;
}

.review-component > .user > .content > .date {
    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;

    color: #C1C1C1;
}

.review-component > .user > .score {
    margin-left: auto;
}

.review-component > .score {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 4px;
}

.review-component > .score > .title {
    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;

    color: #747474;
}

.review-component > .score > .stars {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 4px;
}

.review-component > .score > .stars > svg {
    height: 24px;
    width: 24px;
}

.review-component > .comment {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 4px;
    width: 100%;
}

.review-component > .comment > .title {
    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;

    color: #747474;
}

.review-component > .comment > .comment-text {
    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;

    color: #222222;

    text-wrap: inherit;
    overflow-wrap: break-word;
    word-wrap: break-word;
    white-space: normal;

    width: 100%;
}

.review-component > .comment > .show-more {
    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;

    color: #139CFF;
    cursor: pointer;
}

.review-component > .photo {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
    width: fit-content;
    height: fit-content;
    overflow: hidden;
    border-radius: 8px;
    box-sizing: border-box;
    max-height: 500px;
}

.review-component > .photo > img {
    width: 100%;
}

.review-component > .product-entry {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 14px 8px 8px;
    gap: 8px;

    width: 100%;

    background: #F4F4F9;
    border-radius: 16px;
    box-sizing: border-box;
    cursor: pointer;
    transition: all 0.07s ease-in-out;
}

.review-component > .product-entry:hover {
    opacity: 0.7;
}

.review-component > .product-entry > .image {
    width: 48px;
    height: 48px;

    min-width: 48px;
    min-height: 48px;

    border-radius: 210px;
    overflow: hidden;
}

.review-component > .product-entry > .image > img {
    width: 48px;
    height: 48px;
}

.review-component > .product-entry > .content {
    width: 100%;
    display: flex;
    flex-flow: column;
    gap: 2px;
}

.review-component > .product-entry > .content > .name {
    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;

    color: #222222;
}

.review-component > .product-entry > .content > .rating {

}