.preview-collection {
    margin-bottom: 48px;
}

.preview-collection > .title {
    display: flex;
    align-items: center;
    gap: 12px;

    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 36px;

    color: #222222;
    text-decoration: none;
    cursor: pointer;

    width: fit-content;

    transition: all 0.2s;
}

.preview-collection > .title > span {
    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 36px;

    color: #C1C1C1;
}

.preview-collection > .title:hover {
    opacity: 0.7;
}

.preview-collection > .title > svg {

}

.preview-collection > .products {
    display: flex;
    flex-flow: row;
    justify-content: flex-start;
    gap: 24px;

    margin-top: 24px;
}

@media (max-width: 768px) {
    .preview-collection {
        width: 360px;
    }

    .preview-collection > .title {
        font-family: 'Golos', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 28px;

        color: #222222;
    }

    .preview-collection > .title > span {
        font-size: 24px;
        line-height: 28px;
    }

    .preview-collection > .products {
        /*justify-content: center;*/
    }

    .preview-collection > .products > .preview-product:nth-child(n+3) {
        display: none;
    }
}