.favourite-container {
    display: flex;
    flex-flow: column;
    align-items: center;

    margin: 100px 72px 72px;
}

.favourite-container > .favourite {
    display: flex;
    flex-flow: column;
    align-items: center;

    width: 100%;
    max-width: 1356px;
}

.favourite-container > .favourite > .name {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 12px;

    width: 100%;

    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 36px;

    color: #222222;

    margin-bottom: 36px;
}

.favourite-container > .favourite > .label {
    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 14px;

    color: #747474;
    width: 100%;
}

@media (max-width: 768px) {
    .favourite-container {
        margin: 72px 12px 72px;
    }

    .favourite-container > .favourite > .name {
        font-size: 28px;
        margin-bottom: 6px;
    }

    .favourite-container > .favourite > .label {
        font-size: 14px;
    }
}