.slider-component {
    position: relative;
    width: 100%;
    max-width: 600px;
    margin: auto;
    overflow: hidden;
    box-sizing: border-box;
    height: 100%;
}

.slider-component > .progress-bar {
    position: absolute;
    top: 16px;
    width: 52px;
    height: 5px;
    z-index: 10;
    left: 50%;
    transform: translateX(-50%);


    display: flex;
    flex-direction: row;
    align-items: flex-start;

    background: rgba(255, 255, 255, 0.25);
    box-shadow: 0px -6px 6px rgba(0, 0, 0, 0.01), 0px 12px 26px rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(5px);
    border-radius: 80px;
    box-sizing: border-box;
}

.slider-component > .progress-bar > .progress {
    height: 100%;
    transition: width 0.5s linear;

    display: flex;
    flex-direction: row;
    align-items: flex-start;

    background: rgba(255, 255, 255, 0.25);
    box-shadow: 0px -6px 6px rgba(0, 0, 0, 0.01), 0px 12px 26px rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(5px);
    border-radius: 80px;
}

.slider-component > .slider-images {
    display: flex;
    transition: transform 0.3s ease-in-out;
    width: 100%;
    height: 100%;
    background: #F4F4F9;
}

.slider-component > .slider-images > .slider-image {
    min-width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.slider-component > .slider-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 24px;
    border: none;
    cursor: pointer;
    z-index: 10;
    box-sizing: border-box;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 30px 7px;
    gap: 10px;

    background: rgba(0, 0, 0, 0.55);
    box-shadow: 0px -6px 6px rgba(0, 0, 0, 0.01), 0px 12px 26px rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(5px);
    border-radius: 80px;


    color: #FFFFFF;
}

.slider-component > .slider-button.prev {
    left: 10px;
}

.slider-component > .slider-button.next {
    right: 10px;
}

.slider-component > .slider-pages {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    gap: 8px;
}

.slider-component > .slider-pages > .page-dot {
    /*height: 10px;*/
    /*width: 10px;*/
    /*margin: 0 5px;*/
    /*background-color: #bbb;*/
    /*border-radius: 50%;*/
    display: inline-block;
    cursor: pointer;

    width: 12px;
    height: 12px;

    background: rgba(255, 255, 255, 0.25);
    box-shadow: 0px -6px 6px rgba(0, 0, 0, 0.01), 0px 12px 26px rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(5px);
    border-radius: 80px;

    transition: all 0.2s ease-in-out;
}

.slider-component > .slider-pages > .page-dot.active {
    width: 12px;
    height: 12px;

    background: #FFFFFF;
    border-radius: 80px;
}
