.agree-accept-component {
    z-index: 1400;
}

.agree-accept-component > .cookies-container {
    position: fixed;
    right: 16px;
    bottom: 16px;
    z-index: 1400;
    box-sizing: border-box;
}

.cookies-agree {
    display: flex;
    flex-flow: column;

    max-width: 320px;

    padding: 16px;
    gap: 16px;

    background: #FFFFFF;
    box-shadow: 0px 0px 36.6667px rgba(0, 0, 0, 0.16);
    border-radius: 24px;
    /*box-sizing: border-box;*/
}

.cookies-agree > .header {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;
}

.cookies-agree > .header > svg {
    transition: all 0.2s ease-in-out;
    cursor: pointer;
}

.cookies-agree > .header > svg:hover {
    opacity: 0.7;
}

.cookies-agree > .header > .title {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;

    color: #222222;
}

.cookies-agree > .content {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    width: 90%;
}


.cookies-agree > .content > a {
    color: #139CFF;
}

.cookies-agree > .button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 16px;
    gap: 8px;

    height: 48px;

    background: #EEEEEE;
    border-radius: 12px;
    box-sizing: border-box;

    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;

    color: #222222;

    transition: all 0.2s ease-in-out;
    cursor: pointer;
}

.cookies-agree > .button:hover {
    opacity: 0.7;
}


.age-agree {
    display: flex;
    flex-flow: column;

    /*padding: 16px;*/
    gap: 26px;

    /*background: #FFFFFF;*/
    /*box-shadow: 0px 0px 36.6667px rgba(0, 0, 0, 0.16);*/
    /*border-radius: 24px;*/
    /*box-sizing: border-box;*/
}

.age-agree > .header {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;
}

.age-agree > .header > svg {
    transition: all 0.2s ease-in-out;
    cursor: pointer;
}

.age-agree > .header > svg:hover {
    opacity: 0.7;
}

.age-agree > .header > .title {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;

    color: #222222;
}

.age-agree > .content {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    width: 90%;
}

.age-agree > .content > a {
    color: #139CFF;
}

.age-agree > .buttons {
    display: flex;
    flex-flow: row;
    width: 100%;
    gap: 8px;
}

.age-agree > .buttons > .button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 16px;
    gap: 8px;

    height: 48px;

    background: #EEEEEE;
    border-radius: 12px;
    box-sizing: border-box;

    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;

    color: #222222;

    width: 100%;

    transition: all 0.2s ease-in-out;
    cursor: pointer;

    white-space: nowrap;
}

.age-agree > .buttons > .blue {
    background: #139CFF;
    color: #FFFFFF;
}

.age-agree > .buttons > .button:hover {
    opacity: 0.7;
}
