.product > .bottom > .reviews > .new-review-component {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
    gap: 16px;

    width: 100%;

    background: #F4F4F9;
    border-radius: 12px;
    box-sizing: border-box;
}

.product > .bottom > .reviews > .new-review-component > .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    gap: 4px;
    width: 100%;
}

.product > .bottom > .reviews > .new-review-component > .header > .title {
    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 32px;
    text-align: center;

    color: #222222;
}

.product > .bottom > .reviews > .new-review-component > .header > .stars {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 0px;
    gap: 12px;
}

.product > .bottom > .reviews > .new-review-component > .header > .stars > svg {
    height: 26px;
    width: 26px;
}

.product > .bottom > .reviews > .new-review-component > .body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;

    width: 100%;
}

.product > .bottom > .reviews > .new-review-component > .body > .title {
    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;

    color: #747474;
}

.product > .bottom > .reviews > .new-review-component > .body > .input {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px;
    gap: 10px;

    background: #FFFFFF;
    border-radius: 12px;

    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;

    color: #222222;
    width: 100%;
    box-sizing: border-box;
}

.product > .bottom > .reviews > .new-review-component > .body > .input > input {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    background: #FFFFFF;
    border-radius: 12px;

    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;

    color: #222222;

    width: 100%;

    outline: none;
    border: none;
    box-sizing: border-box;
    height: 100%;
}

.product > .bottom > .reviews > .new-review-component > .buttons {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px 0px 0px;
    gap: 8px;

    width: 100%;
}

.product > .bottom > .reviews > .new-review-component > .buttons > .button-photo {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 16px;
    gap: 8px;

    border: 1px dashed #222222;
    border-radius: 12px;

    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;

    width: 100%;
    box-sizing: border-box;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    color: #222222;
    position: relative;
}

.product > .bottom > .reviews > .new-review-component > .buttons > .button-photo:hover {
    opacity: 0.7;
}

.product > .bottom > .reviews > .new-review-component > .buttons > .button-add {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 16px;
    gap: 8px;

    background: #01ADFF;
    border-radius: 12px;

    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;

    width: 100%;
    box-sizing: border-box;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    color: #FFFFFF;
}

.product > .bottom > .reviews > .new-review-component > .buttons > .button-add:hover {
    opacity: 0.7;
}

.product > .bottom > .reviews > .new-review-component > .buttons > .button-photo > .button-hidden-input {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    bottom: 0;
    left: 0;
    white-space: nowrap;
    width: 1px;
}

.product > .bottom > .reviews > .new-review-component > .buttons > .image-container {
    display: flex;
    flex-flow: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
    width: 100%;
    padding: 16px 0;
}

.product > .bottom > .reviews > .new-review-component > .buttons > .image-container > img {
    max-width: 300px;
}

.product > .bottom > .reviews > .new-review-component > .buttons > .image-container > .ImageUploader-preview-buttons {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 4px 0px 0px;
    gap: 12px;
}

.product > .bottom > .reviews > .new-review-component > .buttons > .image-container > .ImageUploader-preview-buttons > .ImageUploader-preview-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;
    cursor: pointer;
    transition: all 0.1s ease-in-out;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none;
}

.product > .bottom > .reviews > .new-review-component > .buttons > .image-container > .ImageUploader-preview-buttons > .ImageUploader-preview-button:hover {
    opacity: 0.8;
}

.product > .bottom > .reviews > .new-review-component > .buttons > .image-container > .ImageUploader-preview-buttons > .ImageUploader-preview-button:active {
    opacity: 0.6;
}

.product > .bottom > .reviews > .new-review-component > .buttons > .button-phone-hidden {
    display: none;
}

@media (max-width: 768px) {
    .product > .bottom > .reviews > .new-review-component {

    }
}