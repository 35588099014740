.header-component {

}

.header-left {
    display: flex;
    flex-flow: row;

    position: fixed;
    top: 24px;
    left: 24px;
    gap: 12px;

    z-index: 999;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.header-right {
    display: flex;
    flex-flow: row;

    position: fixed;
    top: 24px;
    right: 24px;
    gap: 12px;

    z-index: 999;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.header-container {
    display: flex;
    flex-flow: row;
    gap: 12px;
    align-items: center;
}

.header-container > .button {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 8px 8px 8px;
    gap: 12px;

    background: #FFFFFF;
    box-shadow: 0px -6px 6px rgba(0, 0, 0, 0.01), 0px 12px 26px rgba(0, 0, 0, 0.05);
    border-radius: 49px;

    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
    text-align: center;

    color: #222222;

    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.header-container > .button:hover {
    box-shadow: 0px -6px 6px rgba(0, 0, 0, 0.02), 0px 12px 26px rgba(0, 0, 0, 0.1);
    cursor: pointer;
}

.header-container > .partners {
    padding: 8px 18px 8px 16px;
}

.header-container > .telegram {

}

.header-container > .back {
    padding: 8px 18px 8px 16px;
}

.header-container > .login {
    padding: 8px 18px 8px 16px;
}

.header-container > .register {
    padding: 8px 18px 8px 16px;
}

.header-container > .user-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 6px 18px 6px 6px;
    gap: 12px;

    background: #FFFFFF;
    box-shadow: 0px -6px 6px rgba(0, 0, 0, 0.01), 0px 12px 26px rgba(0, 0, 0, 0.05);
    border-radius: 49px;

    cursor: pointer;
}

.header-container > .user-container > .user {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;
}

.header-container > .user-container > .user > .avatar {
    width: 36px;
    height: 36px;

    border-radius: 170px;
    overflow: hidden;
}

.header-container > .user-container > .user > .avatar > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.header-container > .user-container > .user > .name {
    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
    text-align: center;

    color: #222222;
}

.header-container > .user-container > .arrow {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 10px;
}

.header-right-buttons {
    display: flex;
    flex-flow: row;

    position: fixed;
    top: 80px;
    right: 24px;
    gap: 12px;

    z-index: 999;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.header-right-buttons > .buttons {
    display: flex;
    flex-flow: column;

    gap: 4px;
}

.header-right-buttons > .buttons > .button {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
    gap: 4px;

    width: 280px;

    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #222222;

    background: #FFFFFF;

    box-shadow: 0px -6px 6px rgba(0, 0, 0, 0.01), 0px 12px 26px rgba(0, 0, 0, 0.05);
    border-radius: 16px;

    transition: all 0.2s ease-in-out;
    cursor: pointer;
}

.header-right-buttons > .buttons > .button:hover {
    background: #eeeeee;
}

@media (max-width: 768px) {
    .header-component {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 4px 16px;

        width: 100%;

        background: #FFFFFF;
        box-shadow: 0px -6px 6px rgba(0, 0, 0, 0.01), 0px 12px 26px rgba(0, 0, 0, 0.05);
        border-radius: 0px;
        box-sizing: border-box;
        min-height: 60px;

        position: fixed;
        left: 0;
        top: 0;
        z-index: 13000;
    }

    .header-left {
        position: initial;
    }

    .header-right {
        position: initial;
    }

    .header-container {
        gap: 16px;
    }

    .header-container > .button {
        box-shadow: none;
        padding: initial;
    }

    .header-container > .button:hover {
        box-shadow: none;
    }

    .header-container > .partners {
        font-family: 'Golos', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 32px;
        text-align: center;

        color: #C1C1C1;
    }

    .header-container > .telegram {
        display: none;
    }

    .header-container > .login {
        font-family: 'Golos', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 32px;
        text-align: center;

        color: #222222;
    }

    .header-container > .register {
        font-family: 'Golos', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 32px;
        text-align: center;

        color: #222222;

        display: none;
    }

    .header-container > .back {
        font-family: 'Golos', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 32px;
        text-align: center;

        color: #222222;
    }

    .header-container > .user-container {
        box-shadow: none;
    }

    .header-container > .user-container > .user > .name {
        display: none;
    }
}