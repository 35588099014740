.ImageUploader {

}

.ImageUploader > .ImageUploader-title {
    font-family: 'SF Pro Text', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    color: rgba(4, 4, 19, 0.55);

    margin-bottom: 8px;
}

.ImageUploader > .ImageUploader-preview {
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
    width: 100%;
}

.ImageUploader > .ImageUploader-preview > .MuiButtonBase-root {
    padding: 12px 16px 12px 16px;
    gap: 8px;
    border-radius: 12px;
    text-transform: initial;
    box-shadow: none;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    width: 211px;
    min-width: 88px;
    height: 40px;
    min-height: 40px;

    background: rgba(15, 25, 55, 0.1);

    backdrop-filter: blur(40px);


    font-family: 'SF Pro Text', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;

    color: rgba(3, 3, 6, 0.88);
}

.ImageUploader > .ImageUploader-preview > .MuiButtonBase-root:hover {
    background: #dadada;

    box-shadow: none;
}

.ImageUploader > .ImageUploader-preview-small > img {
    border-radius: 3.57143px;
    height: 100px;
    max-width: 100%;
}

.ImageUploader > .ImageUploader-preview-large > img {
    border-radius: 4px;
    height: 200px;
    max-width: 100%;
}

.ImageUploader > .ImageUploader-preview-small > .MuiButtonBase-root {

}

.ImageUploader > .ImageUploader-preview-large > .MuiButtonBase-root {
    width: 100%;
    padding: 24px 16px 24px 16px;
}

.ImageUploader > .ImageUploader-preview > .ImageUploader-preview-buttons {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 4px 0px 0px;
    gap: 12px;
}

.ImageUploader > .ImageUploader-preview > .ImageUploader-preview-buttons > .ImageUploader-preview-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;
    cursor: pointer;
    transition: all 0.1s ease-in-out;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none;
}

.ImageUploader > .ImageUploader-preview > .ImageUploader-preview-buttons > .ImageUploader-preview-button:hover {
    opacity: 0.8;
}

.ImageUploader > .ImageUploader-preview > .ImageUploader-preview-buttons > .ImageUploader-preview-button:active {
    opacity: 0.6;
}

.ImageUploader > .ImageUploader-preview > .ImageUploader-upload-button {
    flex-flow: column;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    gap: 2px;

    background: rgba(15, 25, 55, 0.1);
    backdrop-filter: blur(40px);
    border-radius: 8px;

    font-family: 'SF Pro Text', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;

    color: rgba(3, 3, 6, 0.88);
    cursor: pointer;
}

.ImageUploader > .ImageUploader-preview > .ImageUploader-upload-button > .ImageUploader-hidden-input {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    bottom: 0;
    left: 0;
    white-space: nowrap;
    width: 1px;
}

.ImageUploader > .ImageUploader-description {
    font-family: 'SF Pro Text', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    color: rgba(4, 4, 19, 0.55);

    margin-top: 4px;
}