.preview-product {
    text-decoration: none;
}

.preview-product > .product {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 8px;
    gap: 6px;

    border-radius: 20px;

    max-width: 252px;
    /*max-height: 480px;*/
    /*height: 100%;*/

    cursor: pointer;

    box-sizing: border-box;
}

.preview-product > .product:hover {
    box-shadow: 0px -6px 6px rgba(0, 0, 0, 0.01), 0px 12px 26px rgba(0, 0, 0, 0.05);
}

.preview-product > .product > .image {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 36px 52px;
    gap: 10px;

    min-width: 236px;
    min-height: 208px;
    max-width: 236px;
    max-height: 208px;

    background: #F4F4F9;
    border-radius: 16px;
    box-sizing: border-box;
}

.preview-product > .product > .image > img {
    height: 100%;
    max-height: 136px;
    max-width: 136px;
    width: 136px;
}

.preview-product > .product > .price-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px 0px 0px;
    gap: 8px;
}

.preview-product > .product > .price-container > .price {
    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;

    color: #222222;
}

.preview-product > .product > .price-container > .gray {
    color: #c1c1c1;
}

.preview-product > .product > .price-container > .size {
    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;

    color: #C1C1C1;
}

.preview-product > .product > .score-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: flex-start;
    padding: 0px;
    gap: 12px;
}

.preview-product > .product > .score-container > .score {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 4px 4px 3px;
    gap: 2px;
    border-radius: 6px;
    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 12px;
    color: #FFFFFF;
    box-sizing: border-box;
}

.preview-product > .product > .score-container > .empty {
    background: #747474;
}

.preview-product > .product > .score-container > .bad {
    background: #FF551C;
}

.preview-product > .product > .score-container > .middle {
    background: #FFAA1C;
}

.preview-product > .product > .score-container > .good {
    background: #57C74E;
}

.preview-product > .product > .score-container > .reviews {
    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;

    color: #C1C1C1;
}

.preview-product > .product > .name {
    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;

    color: #222222;

    width: 100%;

    word-wrap: break-word;
    text-overflow: ellipsis;
    white-space: break-spaces;
    overflow: hidden;
}

.preview-product > .product > .brewery {
    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;

    color: #C1C1C1;
}

.preview-product > .product > .bottom-style {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: flex-start;
    padding: 8px 0px 0px;
    gap: 12px;
    width: 100%;
}

.preview-product > .product > .bottom-style > .style {
    display: flex;
    align-items: center;
    gap: 4px;

    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;

    color: #222222;
}

.preview-product > .product > .bottom-style > .style > svg {
    min-width: 16px;
}

.preview-product > .product > .bottom {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: flex-start;
    justify-content: flex-start;
    padding: 0px 0px 0px;
    gap: 12px;
}

.preview-product > .product > .bottom > .alcohol {
    display: flex;
    align-items: center;
    gap: 4px;

    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;

    color: #222222;
}

.preview-product > .product > .bottom > .alcohol > span {
    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;

    color: #C1C1C1;
}

.preview-product > .product > .bottom > .bitterness {
    display: flex;
    align-items: center;
    gap: 4px;

    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;

    color: #222222;
}

.preview-product > .product > .bottom > .bitterness > span {
    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;

    color: #C1C1C1;
}

@media (max-width: 768px) {
    .preview-product {

    }

    .preview-product > .product {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 1px;
        gap: 3px;

        border-radius: 12px;

        max-width: 166px;
        height: fit-content;
        max-height: initial;
        /*max-height: 480px;*/
        /*height: 100%;*/

        cursor: pointer;

        box-sizing: border-box;
        margin-bottom: initial;
    }

    .preview-product > .product:hover {
        box-shadow: 0px -6px 6px rgba(0, 0, 0, 0.01), 0px 12px 26px rgba(0, 0, 0, 0.05);
    }

    .preview-product > .product > .image {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 24px;
        gap: 8px;

        min-width: 164px;
        min-height: 152px;
        max-width: 164px;
        max-height: 152px;

        background: #F4F4F9;
        border-radius: 12px;
        box-sizing: border-box;
    }

    .preview-product > .product > .image > img {
        height: 100%;
    }

    .preview-product > .product > .price-container > .price {
        font-family: 'Golos', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 23px;

        color: #222222;
    }

    .preview-product > .product > .price-container > .size {
        font-family: 'Golos', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;

        color: #C1C1C1;
    }

    .preview-product > .product > .name {
        font-family: 'Golos', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;

        color: #222222;

        width: 100%;

        word-wrap: break-word;
        text-overflow: ellipsis;
        white-space: break-spaces;
        overflow: hidden;
    }

    .preview-product > .product > .brewery {
        font-family: 'Golos', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 11px;
        line-height: 10px;

        color: #C1C1C1;
    }

    .preview-product > .product > .bottom-style > .style {
        display: flex;
        align-items: center;
        gap: 4px;

        font-family: 'Golos', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;

        color: #222222;
    }

    .preview-product > .product > .bottom > .alcohol {
        display: flex;
        align-items: center;
        gap: 4px;

        font-family: 'Golos', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;

        color: #222222;
    }

    .preview-product > .product > .bottom > .alcohol > span {
        font-family: 'Golos', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;

        color: #C1C1C1;
    }

    .preview-product > .product > .bottom > .bitterness {
        display: flex;
        align-items: center;
        gap: 4px;

        font-family: 'Golos', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;

        color: #222222;
    }

    .preview-product > .product > .bottom > .bitterness > span {
        font-family: 'Golos', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;

        color: #C1C1C1;
    }
}