
.catalog-container {
    display: flex;
    flex-flow: column;
    align-items: center;

    margin: 100px 72px 72px;
}

.catalog-container > .catalog {
    display: flex;
    flex-flow: column;
    align-items: center;

    width: 100%;
    max-width: 1356px;
}

.catalog-container > .catalog > .name {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 12px;

    width: 100%;

    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 36px;

    color: #222222;

    margin-bottom: 36px;
}

.catalog-container > .catalog > .name > a {
    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 36px;

    color: #C1C1C1;

    cursor: pointer;
    text-decoration: none;
    transition: all 0.2s ease-in-out;
}

.catalog-container > .catalog > .name > a:hover {
    opacity: 0.7;
}

.catalog-container > .catalog > .collections {
    width: 100%;
}

.catalog-container > .catalog > .categories {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 24px;
    isolation: isolate;
    flex-wrap: wrap;
}

.catalog-container > .catalog > .categories > .category {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8px;
    gap: 4px;

    width: 252px;
    /*height: 250px;*/

    background: #FFFFFF;
    border-radius: 16px;

    transition: all 0.2s ease-in-out;
    box-sizing: border-box;


    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    text-align: center;

    color: #222222;
}

.catalog-container > .catalog > .categories > .category:hover {
    box-shadow: #0000001c 0 10px 125px;
    cursor: pointer;
}

.catalog-container > .catalog > .categories > .category > .image {
    display: flex;
    flex-direction: row;
    align-items: center;
    /*padding: 36px 52px;*/
    gap: 10px;

    width: 236px;
    height: 208px;

    background: #F4F4F9;
    border-radius: 16px;

    box-sizing: border-box;
    position: relative;
}

.catalog-container > .catalog > .categories > .category > .image > img {
    width: 100%;
    border-radius: 16px;
}

.catalog-container > .catalog > .categories > .category > .image > .label {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);

    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    text-align: center;

    color: #FFFFFF;

    text-shadow: 0px 0px 16px rgba(0, 0, 0, 0.8);
}

.catalog-container > .catalog > .breadcrumbs {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 4px;

    width: 100%;

    margin-bottom: 16px;
}

.catalog-container > .catalog > .breadcrumbs > .item > a {
    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 14px;

    color: #747474;

    text-decoration: none;
}

.catalog-container > .catalog > .breadcrumbs > span {
    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 14px;
    color: #C1C1C1;
}

.catalog-container > .catalog > .breadcrumbs > .last-item {
    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 14px;

    color: #C1C1C1;
}

.catalog-container > .catalog > .brewery-content {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 48px;

    width: 100%;
}

.catalog-container > .catalog > .brewery-content > .brewery-card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
    gap: 24px;

    width: 480px;

    background: #FFFFFF;
    box-shadow: 0px -6px 6px rgba(0, 0, 0, 0.01), 0px 12px 26px rgba(0, 0, 0, 0.05);
    border-radius: 24px;
}

.catalog-container > .catalog > .brewery-content > .brewery-card > .top-container {
    display: flex;
    align-items: flex-start;
    gap: 24px;
}

.catalog-container > .catalog > .brewery-content > .brewery-card > .top-container > .avatar {
    width: 100px;
    height: 100px;
    min-width: 100px;
    min-height: 100px;
    border-radius: 240px;
}

.catalog-container > .catalog > .brewery-content > .brewery-card > .top-container > .avatar > img {
    width: 100%;
    height: 100%;
    border-radius: 240px;
    object-fit: cover;
}


.catalog-container > .catalog > .brewery-content > .brewery-card > .top-container > .top {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
}

.catalog-container > .catalog > .brewery-content > .brewery-card > .top-container > .top > .name {
    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 32px;

    color: #222222;
}

.catalog-container > .catalog > .brewery-content > .brewery-card > .top-container > .top > .address {
    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;

    color: #222222;
}

.catalog-container > .catalog > .brewery-content > .brewery-card > .top-container > .top > .score-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: flex-start;
    padding: 0px;
    gap: 12px;
}

.catalog-container > .catalog > .brewery-content > .brewery-card > .top-container > .top > .score-container > .score {
    display: flex;
    gap: 2px;
    align-items: center;

    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;

    color: #222222;
}

.catalog-container > .catalog > .brewery-content > .brewery-card > .top-container > .top > .score-container > .reviews {
    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;

    color: #C1C1C1;
}

.catalog-container > .catalog > .brewery-content > .brewery-card > .buttons {
    display: flex;
    flex-flow: column;
    width: 100%;
    box-sizing: border-box;
    gap: 12px;
}

.catalog-container > .catalog > .brewery-content > .brewery-card > .buttons > .button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 16px;
    gap: 8px;

    background: #EEEEEE;
    border-radius: 12px;

    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;

    color: #222222;

    width: 100%;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    box-sizing: border-box;
}

.catalog-container > .catalog > .brewery-content > .brewery-card > .buttons > .blue {
    background: #139CFF;
    color: #ffffff;
}

.catalog-container > .catalog > .brewery-content > .brewery-card > .buttons > .button:hover {
    opacity: 0.7;
}

.catalog-container > .catalog > .brewery-content > .brewery-card > .buttons > .buttons-horizontal {
    display: flex;
    flex-flow: row;
    width: 100%;
    box-sizing: border-box;
    gap: 12px;
}

.catalog-container > .catalog > .brewery-content > .brewery-card > .buttons > .buttons-horizontal > .button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 9px 16px;
    gap: 8px;

    background: #EEEEEE;
    border-radius: 12px;

    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;

    color: #222222;

    width: 100%;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    box-sizing: border-box;
}

.catalog-container > .catalog > .brewery-content > .brewery-card > .buttons > .buttons-horizontal > .taxi {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 9px 16px;

    border-radius: 12px;

    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;

    color: #222222;

    white-space: nowrap;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    box-sizing: border-box;

    gap: 11px;

    background: #FFFFFF;
    border: 1px solid #EEEEEE;
}

.catalog-container > .catalog > .brewery-content > .brewery-card > .buttons > .buttons-horizontal > .blue {
    background: #139CFF;
    color: #ffffff;
}

.catalog-container > .catalog > .brewery-content > .brewery-card > .buttons > .buttons-horizontal > .button:hover {
    opacity: 0.7;
}

.catalog-container > .catalog > .brewery-content > .brewery-products {

}

.catalog-container > .catalog > .sub-styles {
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    width: 100%;
    gap: 16px;
}

.catalog-container > .catalog > .sub-styles > .sub-style {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 8px;
    gap: 8px;

    background: #F4F4F9;
    border-radius: 8px;

    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #222222;
}

.sub-style-button {
    box-sizing: border-box;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 8px;
    gap: 8px;

    border: 1px solid #C1C1C1;
    border-radius: 8px;

    cursor: pointer;

    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #222222;
    transition: all 0.2s ease-in-out;
}

.sub-style-button:hover {
    opacity: 0.7;
}

.catalog-container > .catalog > .products {
    display: flex;
    flex-flow: row;
    justify-content: flex-start;
    gap: 24px;

    margin-top: 24px;

    flex-wrap: wrap;
    width: 100%;
}

@media (max-width: 768px) {
    .catalog-container {
        margin: 72px 12px 120px;
    }

    .catalog-container > .catalog {
        justify-content: center;
    }

    .catalog-container > .catalog > .name {
        text-align: center;
        font-size: 28px;
        margin-bottom: 12px;
    }

    .catalog-container > .catalog > .products {
        justify-content: center;
    }

    .catalog-container > .catalog > .categories {
        align-items: center;
        justify-content: center;
    }

    .catalog-container > .catalog > .collections {
        /*justify-content: center;*/
        display: flex;
        flex-flow: column;
        align-items: center;
    }

    .catalog-container > .catalog > .brewery-content {
        flex-flow: column;
    }

    .catalog-container > .catalog > .brewery-content > .brewery-card {
        width: 100%;
        box-sizing: border-box;
    }

    .catalog-container > .catalog > .brewery-content > .brewery-card > .top-container > .avatar {
        width: 84px;
        height: 84px;
        min-width: 84px;
        min-height: 84px;
        border-radius: 240px;
    }

    .catalog-container > .catalog > .brewery-content > .brewery-card > .top-container > .top > .name {
        font-size: 24px;
        word-break: break-word;
    }

    .catalog-container > .catalog > .brewery-content > .brewery-card > .top-container > .top > .address {
        font-size: 14px;
    }

    .catalog-container > .catalog > .brewery-content > .brewery-card > .buttons > .buttons-horizontal {
        flex-flow: column;
    }

    .catalog-container > .catalog > .brewery-content > .brewery-card > .buttons > .buttons-horizontal > .button {
        width: 100%;
        padding: 12px 16px;
    }

    .catalog-container > .catalog > .brewery-content > .brewery-card > .buttons > .buttons-horizontal > .taxi {
        padding: 9px 16px;
    }
}