.product-container {
    display: flex;
    flex-flow: column;
    align-items: center;

    margin: 100px 72px 72px;
}

.product {
    display: flex;
    flex-flow: column;
    align-items: center;

    width: 100%;
    max-width: 1356px;
    margin-bottom: 48px;
}

.product > .header {
    width: 100%;
}

.product > .header > .breadcrumbs {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 4px;
    flex-wrap: wrap;
}

.product > .header > .breadcrumbs > .item > a {
    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 14px;

    color: #747474;

    text-decoration: none;
    white-space: nowrap;
}

.product > .header > .breadcrumbs > span {
    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 14px;
    color: #C1C1C1;
}

.product > .header > .breadcrumbs > .last-item {
    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 14px;

    color: #C1C1C1;
    white-space: nowrap;
}

.product > .header > .title {
    margin-top: 12px;
    margin-bottom: 6px;

    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 32px;

    color: #222222;
}

.product > .header > .score-container {
    margin-top: 12px;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: flex-start;
    padding: 0px;
    gap: 12px;
}

.product > .header > .score-container > .score {
    display: flex;
    gap: 2px;
    align-items: center;

    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;

    color: #222222;
}

.product > .header > .score-container > .reviews {
    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;

    color: #C1C1C1;
}

.product > .content {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;

    width: 100%;
    /*height: 580px;*/

    margin-top: 24px;
    margin-bottom: 32px;
}

.product > .content > .image-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;

    max-width: 512px;
    width: 100%;

    box-sizing: border-box;
}

.product > .content > .image-container > .avatar {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 36px 52px;
    gap: 8px;

    width: 100%;
    height: 450px;

    background: #F4F4F9;
    border-radius: 16px;

    box-sizing: border-box;
}

.product > .content > .image-container > .avatar > img {
    width: 100%;
}

.product > .content > .image-container > .review-images-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;

    max-width: 512px;
}

.product > .content > .image-container > .review-images-container > .review-image {
    width: 72px;
    height: 72px;

    border-radius: 16px;
    overflow: hidden;
}

.product > .content > .image-container > .review-images-container > .review-image > img {
    width: 100%;
}

.product > .content > .right-container {
    display: flex;
    flex-direction: row;
    padding: 0px;
    gap: 16px;

    width: 768px;
}

.product > .content > .right-container > .info-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
    gap: 16px;

    width: 392px;

    background: #FFFFFF;
    border-radius: 24px;
}

.product > .content > .right-container > .info-container > .info-block {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 4px;
    width: 100%;
}

.product > .content > .right-container > .info-container > .info-block > .title {
    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;

    color: #C1C1C1;
}

.product > .content > .right-container > .info-container > .info-block > .content {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;
    width: 100%;
}

.product > .content > .right-container > .info-container > .info-block > .content > .content-icon {

    width: 20px;
    height: 20px;

    border-radius: 120px;
    overflow: hidden;
}

.product > .content > .right-container > .info-container > .info-block > .content > .content-icon > img {
    width: 100%;
}

.product > .content > .right-container > .info-container > .info-block > .content > a {
    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    color: #222222;
    text-decoration: none;
}

.product > .content > .right-container > .info-container > .info-block > .content > .content-link {
    display: flex;
    align-items: center;
    gap: 8px;

    transition: all 0.2s ease-in-out;
}

.product > .content > .right-container > .info-container > .info-block > .content > .content-link-array {
    display: flex;
    align-items: center;
    gap: 8px;
}

.product > .content > .right-container > .info-container > .info-block > .content > .content-link-array > a {
    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;

    color: #222222;
    transition: all 0.2s ease-in-out;
}

.product > .content > .right-container > .info-container > .info-block > .content > .content-link-array > a:hover {
    opacity: 0.7;
    color: #139CFF;
}

.product > .content > .right-container > .info-container > .info-block > .content > .content-link:hover {
    opacity: 0.7;
}

.product > .content > .right-container > .info-container > .info-block > .content > .content-text {
    display: flex;
    align-items: center;
    gap: 4px;

    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;

    color: #222222;

    word-wrap: break-word;
    text-overflow: ellipsis;
    white-space: break-spaces;
    overflow: hidden;
}

.product > .content > .right-container > .info-container > .info-block > .content > .content-text-big {
    display: flex;
    gap: 4px;

    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;

    color: #222222;

    word-wrap: break-word;
    text-overflow: ellipsis;
    white-space: break-spaces;
    overflow: hidden;

    max-height: 90px;
}

.product > .content > .right-container > .info-container > .info-block > .content > .content-param {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 8px 12px;
    gap: 2px;

    background: #F4F4F9;
    border-radius: 16px;

    min-width: 115px;
    box-sizing: border-box;
    width: 100%;
}

.product > .content > .right-container > .info-container > .info-block > .content > .content-param > .value {
    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;

    color: #222222;
}

.product > .content > .right-container > .info-container > .info-block > .content > .content-param > .field {
    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #222222;
}

.product > .content > .right-container > .partners-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
    gap: 12px;

    background: #FFFFFF;
    box-shadow: 0px -6px 6px rgba(0, 0, 0, 0.01), 0px 12px 26px rgba(0, 0, 0, 0.05);
    border-radius: 24px;

    width: 360px;
    height: fit-content;
    box-sizing: border-box;
}

.product > .content > .right-container > .partners-container > .price-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;

    width: 100%;
}

.product > .content > .right-container > .partners-container > .price-container > .price {
    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 32px;

    color: #222222;
}

.product > .content > .right-container > .partners-container > .price-container > .size {
    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;

    color: #C1C1C1;
}

.product > .content > .right-container > .partners-container > .shops-label {
    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;

    color: #222222;

    width: 100%;
}

.product > .content > .right-container > .partners-container > .shops {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;

    width: 100%;
}

.product > .content > .right-container > .partners-container > .shops > .shop {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 14px 8px 8px;
    gap: 10px;

    background: #F4F4F9;
    border-radius: 16px;

    width: 100%;
    box-sizing: border-box;

    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.product > .content > .right-container > .partners-container > .shops > .shop:hover {
    background: #F1F1F1;
}

.product > .content > .right-container > .partners-container > .shops > .shop > .avatar {
    width: 48px;
    height: 48px;
    min-width: 48px;
    min-height: 48px;

    border-radius: 30px;
    overflow: hidden;
}

.product > .content > .right-container > .partners-container > .shops > .shop > .avatar > img {
    width: 100%;
    height: 100%;
    border-radius: 30px;
    object-fit: cover;
}

.product > .content > .right-container > .partners-container > .shops > .shop > .body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 2px;

    width: 100%;
}

.product > .content > .right-container > .partners-container > .shops > .shop > .body > .top {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 6px;

    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;

    color: #222222;
}

.product > .content > .right-container > .partners-container > .shops > .shop > .body > .top > span {
    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #C1C1C1;
}

.product > .content > .right-container > .partners-container > .shops > .shop > .body > .bottom {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;
}

.product > .content > .right-container > .partners-container > .shops > .shop > .body > .bottom > .score-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;
}

.product > .content > .right-container > .partners-container > .shops > .shop > .body > .bottom > .score-container > .name {
    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;

    color: #222222;
}

.product > .content > .right-container > .partners-container > .shops > .shop > .body > .bottom > .score-container > .score {
    display: flex;
    align-items: center;
    gap: 4px;

    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;

    color: #222222;
}

.product > .content > .right-container > .partners-container > .shops > .shop > .icon {

}

.product > .content > .right-container > .partners-container > .shops > .button-shops-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 14px;
    gap: 10px;

    background: #FFFFFF;
    border-radius: 16px;

    width: 100%;
    box-sizing: border-box;

    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.product > .content > .right-container > .partners-container > .shops > .button-shops-container:hover {
    opacity: 0.7;
}

.product > .content > .right-container > .partners-container > .shops > .button-shops-container > .body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 2px;

    width: 285px;
}

.product > .content > .right-container > .partners-container > .shops > .button-shops-container > .body > .top {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 6px;

    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;

    color: #222222;
}

.product > .content > .right-container > .partners-container > .shops > .button-shops-container > .body > .bottom {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;
}

.product > .content > .right-container > .partners-container > .shops > .button-shops-container > .icon {

}

.product > .content > .right-container > .partners-container > .shops > .button-shops-map {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 14px;
    gap: 6px;

    width: 100%;
    height: 40px;

    background: #FFFFFF;
    border-radius: 16px;

    box-sizing: border-box;

    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.product > .content > .right-container > .partners-container > .shops > .button-shops-map:hover {
    opacity: 0.7;
}

.product > .content > .right-container > .partners-container > .shops > .button-shops-map > .map {
    width: 24px;
    height: 24px;
}

.product > .content > .right-container > .partners-container > .shops > .button-shops-map > .label {
    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;

    color: #222222;

    width: 250px;
}

.product > .content > .right-container > .partners-container > .shops > .button-shops-map > .icon {

}

.product > .bottom {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    padding: 0px 48px;
    gap: 24px;

    width: 100%;
    margin-top: 8px;
}

.product > .bottom > .reviews {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
    gap: 16px;

    width: 636px;

    background: #FFFFFF;
    box-shadow: 0px -6px 6px rgba(0, 0, 0, 0.01), 0px 12px 26px rgba(0, 0, 0, 0.05);
    border-radius: 24px;
}

.product > .bottom > .reviews > .new-review {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
    gap: 16px;

    width: 100%;

    background: #F4F4F9;
    border-radius: 12px;
    box-sizing: border-box;
}

.product > .bottom > .reviews > .new-review > .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    gap: 4px;
    width: 100%;
}

.product > .bottom > .reviews > .new-review > .header > .title {
    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 32px;
    text-align: center;

    color: #222222;
}

.product > .bottom > .reviews > .new-review > .header > .stars {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 0px;
    gap: 12px;
}

.product > .bottom > .reviews > .new-review > .header > .stars > svg {
    height: 26px;
    width: 26px;
}

.product > .bottom > .reviews > .new-review > .body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;

    width: 100%;
}

.product > .bottom > .reviews > .new-review > .body > .title {
    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;

    color: #747474;
}

.product > .bottom > .reviews > .new-review > .body > .input {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px;
    gap: 10px;

    background: #FFFFFF;
    border-radius: 12px;

    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;

    color: #222222;
    width: 100%;
    box-sizing: border-box;
}

.product > .bottom > .reviews > .new-review > .body > .input > input {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    background: #FFFFFF;
    border-radius: 12px;

    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;

    color: #222222;

    width: 100%;

    outline: none;
    border: none;
    box-sizing: border-box;
    height: 100%;
}

.product > .bottom > .reviews > .new-review > .buttons {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px 0px 0px;
    gap: 8px;

    width: 100%;
}

.product > .bottom > .reviews > .new-review > .buttons > .button-photo {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 16px;
    gap: 8px;

    border: 1px dashed #222222;
    border-radius: 12px;

    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;

    width: 100%;
    box-sizing: border-box;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    color: #222222;
}

.product > .bottom > .reviews > .new-review > .buttons > .button-photo:hover {
    opacity: 0.7;
}

.product > .bottom > .reviews > .new-review > .buttons > .button-add {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 16px;
    gap: 8px;

    background: #01ADFF;
    border-radius: 12px;

    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;

    width: 100%;
    box-sizing: border-box;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    color: #FFFFFF;
}

.product > .bottom > .reviews > .new-review > .buttons > .button-add:hover {
    opacity: 0.7;
}

.product > .bottom > .reviews > .review {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 12px;
    gap: 12px;

    width: 100%;

    border-radius: 12px;
    box-sizing: border-box;
}

.product > .bottom > .reviews > .review > .user {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;

    width: 100%;
}

.product > .bottom > .reviews > .review > .user > .avatar {
    width: 30px;
    height: 30px;

    border-radius: 30px;
    overflow: hidden;
}

.product > .bottom > .reviews > .review > .user > .avatar > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.product > .bottom > .reviews > .review > .user > .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0px;
}

.product > .bottom > .reviews > .review > .user > .content > .name {
    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    color: #222222;
}

.product > .bottom > .reviews > .review > .user > .content > .date {
    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;

    color: #C1C1C1;
}

.product > .bottom > .reviews > .review > .user > .score {
    margin-left: auto;
}

.product > .bottom > .reviews > .review > .score {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 4px;
}

.product > .bottom > .reviews > .review > .score > .title {
    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;

    color: #747474;
}

.product > .bottom > .reviews > .review > .score > .stars {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 4px;
}

.product > .bottom > .reviews > .review > .score > .stars > svg {
    height: 24px;
    width: 24px;
}

.product > .bottom > .reviews > .review > .comment {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 4px;
}

.product > .bottom > .reviews > .review > .comment > .title {
    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;

    color: #747474;
}

.product > .bottom > .reviews > .review > .comment > .comment {
    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;

    color: #222222;
}

.product > .bottom > .reviews > .review > .photo {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
    width: fit-content;
    height: fit-content;
    overflow: hidden;
    border-radius: 8px;
    box-sizing: border-box;
    max-height: 500px;
}

.product > .bottom > .reviews > .review > .photo > img {
    width: 100%;
}

.product > .bottom > .map-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
    gap: 16px;

    width: 636px;

    border-radius: 24px;
}

.product > .bottom > .map-container > .title-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 4px;
}

.product > .bottom > .map-container > .title-container > .title {
    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 32px;

    color: #222222;
}

.product > .bottom > .map-container > .title-container > .description {
    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;

    color: #222222;
}

.product > .bottom > .map-container > .map {
    width: 604px;
    height: 296px;

    background: #F4F4F9;
    border-radius: 8px;
    position: relative;
    box-sizing: border-box;
    overflow: hidden;
}

.product > .bottom > .map-container > .openedMap {
    height: 400px;
}

.product > .bottom > .map-container > .map > .blur {
    position: absolute;
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 0%;

    background: rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(7.5px);
}

.product > .bottom > .map-container > .map > img {
    width: 100%;
    height: 100%;
}

.product > .bottom > .map-container > .map > .button-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 24px;
    gap: 10px;

    position: absolute;
    left: 23.68%;
    right: 23.68%;
    top: 39.36%;
    bottom: 39.36%;

    background: #FFFFFF;
    border-radius: 24px;

    z-index: 3;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.product > .bottom > .map-container > .map > .button-container:hover {
    opacity: 0.7;
}

.product > .bottom > .map-container > .map > .button-container > .button {
    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 31px;

    color: #222222;
}

.product > .bottom > .map-container > .map > .disabled {
    cursor: initial !important;
    opacity: 1 !important;
}

.product > .bottom > .map-container > .map > .disabled > .button {
    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 31px;

    color: #C1C1C1;
}

.product > .bottom > .map-container > .taxi {
    width: 176px;

    border-radius: 8px;
    overflow: hidden;
    cursor: pointer;
}

.product > .bottom > .map-container > .taxi > img {
    width: 100%;
}

.product > .bottom > .map-container > .active-map-store {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
    gap: 24px;
    width: 480px;
    background: #FFFFFF;
    box-shadow: 0px -6px 6px rgba(0, 0, 0, 0.01), 0px 12px 26px rgba(0, 0, 0, 0.05);
    border-radius: 24px;
}

.product > .bottom > .map-container > .active-map-store > .top-container {
    display: flex;
    align-items: flex-start;
    gap: 24px;
}

.product > .bottom > .map-container > .active-map-store > .top-container > .avatar {
    width: 100px;
    height: 100px;
    min-width: 100px;
    min-height: 100px;
    border-radius: 240px;
}

.product > .bottom > .map-container > .active-map-store > .top-container > .avatar > img {
    width: 100%;
    height: 100%;
    border-radius: 240px;
    object-fit: cover;
}

.product > .bottom > .map-container > .active-map-store > .top-container > .top {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
}

.product > .bottom > .map-container > .active-map-store > .top-container > .top > .name {
    display: flex;
    flex-flow: row;
    gap: 8px;
    align-items: center;

    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 32px;
    color: #222222;
}

.product > .bottom > .map-container > .active-map-store > .top-container > .top > .name > svg {
    height: 16px;
}

.product > .bottom > .map-container > .active-map-store > .top-container > .top > .address {
    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;

    color: #222222;
}

.product > .bottom > .map-container > .active-map-store > .top-container > .top > .taxi {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 9px 16px;

    border-radius: 12px;

    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;

    color: #222222;

    width: fit-content;
    white-space: nowrap;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    box-sizing: border-box;

    gap: 11px;

    background: #FFFFFF;
    border: 1px solid #EEEEEE;
}

.product > .bottom > .map-container > .active-map-store > .top-container > .top > .taxi:hover {
    opacity: 0.7;
}

.product > .bottom > .map-container > .active-map-store > .buttons {
    display: flex;
    width: 100%;
}

.product > .bottom > .map-container > .active-map-store > .buttons > .button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 16px;
    gap: 8px;
    background: #EEEEEE;
    border-radius: 12px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #222222;
    width: 100%;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.product > .bottom > .map-container > .active-map-store > .buttons > .button:hover {
    opacity: 0.7;
}

.product > .bottom > .map-container > .active-map-store > .buttons > .taxi {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 9px 16px;

    border-radius: 12px;

    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;

    color: #222222;

    width: fit-content;
    white-space: nowrap;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    box-sizing: border-box;

    gap: 11px;

    background: #FFFFFF;
    border: 1px solid #EEEEEE;
}

.product-shops-modal {
    display: flex;
    flex-flow: column;
    gap: 16px;
}

.product-shops-modal > .label {

}

.product-shops-modal > .shops {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;

    width: 100%;
}

.product-shops-modal > .shops > .shop {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 14px 8px 8px;
    gap: 10px;

    background: #F4F4F9;
    border-radius: 16px;

    width: 100%;
    box-sizing: border-box;

    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.product-shops-modal > .shops > .shop:hover {
    background: #F1F1F1;
}

.product-shops-modal > .shops > .shop > .avatar {
    width: 48px;
    height: 48px;

    border-radius: 30px;
    overflow: hidden;
}

.product-shops-modal > .shops > .shop > .avatar > img {
    width: 100%;
}

.product-shops-modal > .shops > .shop > .body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 2px;

    width: 100%;
}

.product-shops-modal > .shops > .shop > .body > .top {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 6px;

    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;

    color: #222222;
}

.product-shops-modal > .shops > .shop > .body > .top > span {
    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #C1C1C1;
}

.product-shops-modal > .shops > .shop > .body > .bottom {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;
}

.product-shops-modal > .shops > .shop > .body > .bottom > .score-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;
}

.product-shops-modal > .shops > .shop > .body > .bottom > .score-container > .name {
    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;

    color: #222222;
}

.product-shops-modal > .shops > .shop > .body > .bottom > .score-container > .address {
    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;

    color: #747474;
}

.product-shops-modal > .shops > .shop > .icon {

}

@media (max-width: 768px) {
    .product-container {
        margin: 72px 12px 120px;
    }

    .product > .content {
        flex-flow: column;
        align-items: center;
    }

    .product > .content > .right-container {
        flex-flow: column-reverse;
        width: 100%;
        box-sizing: border-box;
    }

    .product > .content > .right-container > .partners-container {
        width: 100%;
        box-sizing: border-box;
    }

    .product > .content > .right-container > .info-container {
        width: 100%;
        box-sizing: border-box;
    }

    .product > .content > .right-container > .info-container > .info-block > .content > .content-link-array {
        display: flex;
        align-items: flex-start;
        gap: 8px;
        flex-flow: column;
    }

    .product > .bottom {
        flex-flow: column-reverse;
        box-sizing: border-box;
        padding: 0px;
    }

    .product > .bottom > .reviews {
        width: 100%;
        box-sizing: border-box;
    }

    .product > .bottom > .map-container {
        width: 100%;
        box-sizing: border-box;
    }

    .product > .bottom > .map-container > .map {
        width: 100%;
        box-sizing: border-box;
    }

    .product > .bottom > .map-container > .active-map-store {
        width: 100%;
        box-sizing: border-box;

    }

    .product > .bottom > .map-container > .active-map-store > .top-container > .avatar {
        width: 48px;
        height: 48px;
        min-width: 48px;
        min-height: 48px;
        border-radius: 240px;
    }

    .product > .bottom > .map-container > .active-map-store > .top-container > .top > .name {
        font-size: 24px;
        word-break: break-word;
    }

    .product > .bottom > .map-container > .active-map-store > .top-container > .top > .name > svg {
        height: 12px;
    }

    .product > .bottom > .map-container > .active-map-store > .top-container > .top > .address {
        font-size: 14px;
    }
}