.fade {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.fade.fade-in {
  opacity: 1;
  transform: translateY(0);
}

.fade.fade-out {
  opacity: 0;
  transform: translateY(20px);
}