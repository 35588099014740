.home {
    margin: 40px;
}

.home > .categories {
    display: flex;
    justify-items: center;
    gap: 16px
}

.home > .categories > .category {
    display: flex;
    justify-items: center;

    padding: 16px;
    box-sizing: border-box;

    border-radius: 20px;
    width: 125px;
    height: 125px;
    box-shadow: #0000001c 0 10px 125px;

    transition: all 0.2s ease-in-out;
}

.home > .categories > .category:hover {
    box-shadow: #0000005e 0 10px 125px;
    cursor: pointer;
}

.catalog-main-slider {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    padding: 0px;
    gap: 24px;

    height: 468px;
    width: 100%;
    margin-bottom: 24px;
    max-width: 1356px;
}

.catalog-main-slider > .slider-component {
    width: 768px;
    height: 100%;

    max-width: 768px;
    border-radius: 32px;
}

.catalog-main-slider > .catalog-main-quote {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 32px;
    gap: 10px;

    width: 504px;
    height: 100%;

    background: #F4F4F9;
    border-radius: 32px;
    box-sizing: border-box;
}

.catalog-main-slider > .catalog-main-quote > .quote {
    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 32px;

    color: #222222;
}

.catalog-main-slider > .catalog-main-quote > .avatar-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;

    width: 100%;
}

.catalog-main-slider > .catalog-main-quote > .avatar-container > .avatar {

}

.catalog-main-slider > .catalog-main-quote > .avatar-container > .avatar > img {
    width: 48px;
    height: 48px;

    border-radius: 640px;
}

.catalog-main-slider > .catalog-main-quote > .avatar-container > .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0px;
    gap: 4px;
}

.catalog-main-slider > .catalog-main-quote > .avatar-container > .content > .title {
    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;

    color: #222222;
}

.catalog-main-slider > .catalog-main-quote > .avatar-container > .content > .description {
    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;

    color: #C1C1C1;
}

.catalog-navigator {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    gap: 24px;
    width: 100%;
    margin-top: 60px;
    margin-bottom: 60px;
}

.catalog-navigator > .title {
    display: flex;
    align-items: center;
    gap: 12px;
    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 36px;
    color: #222222;
    text-decoration: none;
    cursor: pointer;
    width: fit-content;
    transition: all 0.2s;
}

.catalog-navigator > .labels {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 12px;

    margin: 0 auto;
    width: 100%;
}

.catalog-navigator > .labels > .label {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px 20px 4px 4px;
    gap: 12px;

    border-radius: 50px;
    border: 2px solid transparent;
    cursor: pointer;
    transition: all 0.07s ease-in-out;

    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 36px;
    text-align: center;

    color: #222222;
    white-space: nowrap;
}

.catalog-navigator > .labels > .catalog-link {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px 20px 4px 4px;
    gap: 12px;

    background: #EEEEEE;
    border-radius: 50px;
    margin-left: auto;
}

.catalog-navigator > .labels > .catalog-link > .avatar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;

    width: 48px;
    height: 48px;
    min-width: 48px;
    min-height: 48px;

    background: #222222;
    border-radius: 60px;
}

.catalog-navigator > .labels > .label:hover {
    border: 2px solid rgba(0, 0, 0, 0.1);
}

.catalog-navigator > .labels > .label > img {
    width: 48px;
    height: 48px;

    border-radius: 60px;
}

.catalog-navigator > .labels > .active {
    border: 2px solid #139CFF !important;
}

.catalog-navigator > .collections {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 24px;
}

.catalog-navigator > .collections > .collection {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 8px;
    gap: 6px;
    border-radius: 20px;
    max-width: 252px;
    box-sizing: border-box;
    cursor: pointer;
}

.catalog-navigator > .collections > .collection:hover {
    box-shadow: 0px -6px 6px rgba(0, 0, 0, 0.01), 0px 12px 26px rgba(0, 0, 0, 0.05);
}

.catalog-navigator > .collections > .collection > .name {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-align: center;

    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;

    color: #222222;
    width: 100%;
}

.catalog-navigator > .collections > .collection > .image {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 36px 52px;
    gap: 10px;

    width: 236px;
    height: 208px;

    background: #F4F4F9;
    border-radius: 16px;
    box-sizing: border-box;
}

.catalog-navigator > .collections > .collection > .image > img {
    height: 100%;
    max-height: 136px;
    max-width: 136px;
    width: 136px;
}

.catalog-navigator > .collections > .collection > .text-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 24px;
    gap: 8px;

    width: 236px;
    height: 208px;

    background: #F4F4F9;
    border-radius: 16px;
    box-sizing: border-box;
}

.catalog-navigator > .collections > .collection > .text-container > .text {
    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;

    color: #222222;
}

.catalog-navigator > .not-found-label {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #222222;
    width: 100%;
    padding: 20px;
}

.main-banner {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;

    width: 100%;
    height: 252px;

    background: #F4F4F9;
    border-radius: 32px;
    margin-bottom: 48px;
    overflow: hidden;
    box-sizing: border-box;
}

.main-banner > .image {
    width: 287px;
    height: 100%;
}

.main-banner > .image > img {
    width: 287px;
    height: 252px;
    object-fit: cover;
}

.main-banner > .content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: 32px;
    gap: 16px;

    width: 100%;
    height: 100%;
    box-sizing: border-box;
}

.main-banner > .content > .before {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;

    color: #747474;
}

.main-banner > .content > .title {
    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 42px;

    color: #222222;

    width: 584px;
}

.main-banner > .content > .tags {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-end;
    align-content: flex-start;
    padding: 8px 0px 0px;
    gap: 12px;
}

.main-banner > .content > .tags > .tag {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 12px 16px;
    gap: 8px;

    background: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(8.20727px);
    border-radius: 16px;

    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #222222;
}

@media (max-width: 768px) {
    .catalog-main-slider {
        flex-direction: column;
        height: fit-content;
        width: 100%;
    }

    .catalog-main-slider > .slider-component {
        width: 100%;
        height: 54vw;
    }

    .catalog-main-slider > .catalog-main-quote {
        width: fit-content;
    }

    .catalog-main-slider > .catalog-main-quote > .quote {
        font-family: 'Golos', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;

        color: #222222;
    }

    .catalog-navigator > .title {
        font-family: 'Golos', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 28px;
        color: #222222;
    }

    .catalog-navigator > .labels {
        width: 100%;
        overflow-y: scroll;
    }

    .catalog-navigator > .collections {
        flex-wrap: wrap;
    }

    .catalog-navigator > .collections > .collection {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 1px;
        gap: 3px;
        border-radius: 12px;
        max-width: 166px;
        height: fit-content;
        max-height: initial;
        cursor: pointer;
        box-sizing: border-box;
        margin-bottom: initial;
    }

    .catalog-navigator > .collections > .collection > .image {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 24px;
        gap: 8px;
        min-width: 164px;
        min-height: 152px;
        max-width: 164px;
        max-height: 152px;
        background: #F4F4F9;
        border-radius: 12px;
        box-sizing: border-box;
    }

    .catalog-navigator > .collections > .collection > .image > img {
        height: 100%;
    }

    .catalog-navigator > .collections > .collection > .name {
        font-family: 'Golos', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #222222;
        width: 100%;

        max-height: 44px;
    }

    .catalog-navigator > .collections > .for-text {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 12px 16px;
        gap: 10px;

        width: 100%;

        background: #F4F4F9;
        border-radius: 16px;
        max-width: initial;
    }

    .catalog-navigator > .collections > .collection > .text-container {
        width: 100%;
        height: fit-content;
        align-items: center;
        padding: 4px;
    }

    .catalog-navigator > .collections > .collection > .text-container > svg {
        display: none;
    }

    .catalog-navigator > .not-found-label {
        width: 100%;
        padding: 0;
    }

    .main-banner {
        flex-flow: column;
        height: fit-content;
        width: 360px;
    }

    .main-banner > .image {
        width: 100%;
    }

    .main-banner > .image > img {
        width: 100%;
        height: 148px;
    }

    .main-banner > .content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 16px;
        gap: 12px;
    }

    .main-banner > .content > .before {
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;

        color: #747474;
    }

    .main-banner > .content > .title {
        font-family: 'Golos', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 21px;
        line-height: 28px;

        color: #222222;

        width: 100%;
    }

    .main-banner > .content > .tags {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: flex-end;
        align-content: flex-start;
        padding: 8px 0px 0px;
        gap: 8px;
    }

    .main-banner > .content > .tags > .tag {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 8px 12px;
        gap: 8px;

        background: rgba(255, 255, 255, 0.8);
        backdrop-filter: blur(8.20727px);
        border-radius: 16px;

        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;

        color: #222222;
    }
}