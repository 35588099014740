.search-container {
    display: flex;
    flex-flow: column;
    align-items: center;

    margin: 100px 72px 72px;
}

.search-container > .search {
    display: flex;
    flex-flow: column;
    align-items: center;

    width: 100%;
    max-width: 1356px;
}

.search-container > .search > .name {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 12px;

    width: 100%;

    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 36px;

    color: #222222;

    margin-bottom: 36px;
}

.search-container > .search > .name > a {
    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 36px;

    color: #C1C1C1;

    cursor: pointer;
    text-decoration: none;
    transition: all 0.2s ease-in-out;
}

.search-container > .search > .name > a:hover {
    opacity: 0.7;
}

.search-container > .search > .content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 24px;
    isolation: isolate;
    flex-wrap: wrap;
    width: 100%;
}

.search-container > .search > .content > .search-component {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 12px 12px 24px;
    gap: 16px;


    background: #FFFFFF;
    box-shadow: 0px -6px 6px rgba(0, 0, 0, 0.01), 0px 12px 26px rgba(0, 0, 0, 0.05);
    border-radius: 24px;

    min-width: 540px;
    box-sizing: border-box;
}

.search-container > .search > .content > .search-component > input {
    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    align-items: center;

    color: #222222;
    border: none;
    outline: none;
    height: 48px;
    width: 100%;
}

.search-container > .search > .content > .search-component > .clear {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    width: 32px;
    transition: all 0.07s ease-in-out;
    cursor: pointer;
}

.search-container > .search > .content > .search-component > .clear:hover {
    opacity: 0.7;
}

.search-container > .search > .content > .search-component > .clear:active {
    opacity: 0.2;
}

.search-container > .search > .content > .search-component > .button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 36px;
    gap: 8px;

    background: #139CFF;
    border-radius: 12px;

    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;

    color: #FFFFFF;

    transition: all 0.07s ease-in-out;
    cursor: pointer;
}

.search-container > .search > .content > .search-component > .button:hover {
    opacity: 0.7;
}

.search-container > .search > .content > .search-component > .button:active {
    opacity: 0.5;
}

.search-container > .search > .content > .products-container {

}

.search-container > .search > .content > .products-container  > .label {

}

.search-container > .search > .content > .products-container > .products {
    display: flex;
    flex-flow: row;
    justify-content: flex-start;
    gap: 24px;

    margin-top: 24px;

    flex-wrap: wrap;
    width: 100%;
}

@media (max-width: 768px) {
    .search-container {
        margin: 72px 12px 72px;
    }

    .search-container > .search > .content > .search-component {
        min-width: initial;
    }

    .search-container > .search > .name {
        font-size: 28px;
        margin-bottom: 12px;
    }
}