.user-profile-container {
    display: flex;
    flex-flow: column;
    align-items: center;

    margin: 100px 72px 72px;
}

.user-profile-container > .user-profile {
    display: flex;
    flex-flow: column;
    align-items: center;

    width: 100%;
    max-width: 1356px;
}

.user-profile-container > .user-profile > .breadcrumbs {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 4px;

    width: 100%;

    margin-bottom: 16px;
}

.user-profile-container > .user-profile > .breadcrumbs > .item {
    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 14px;

    color: #747474;

    text-decoration: none;
}

.user-profile-container > .user-profile > .breadcrumbs > .item > a {
    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 14px;

    color: #747474;

    text-decoration: none;
}

.user-profile-container > .user-profile > .breadcrumbs > span {
    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 14px;
    color: #C1C1C1;
}

.user-profile-container > .user-profile > .breadcrumbs > .last-item {
    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 14px;

    color: #C1C1C1;
}

.user-profile-container > .user-profile > .user-profile-content {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 48px;

    width: 100%;
}

.user-profile-container > .user-profile > .user-profile-content > .user-profile-card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
    gap: 24px;

    width: 480px;

    background: #FFFFFF;
    box-shadow: 0px -6px 6px rgba(0, 0, 0, 0.01), 0px 12px 26px rgba(0, 0, 0, 0.05);
    border-radius: 24px;
}

.user-profile-container > .user-profile > .user-profile-content > .user-profile-card > .top {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 24px;
    width: 100%;
}

.user-profile-container > .user-profile > .user-profile-content > .user-profile-card > .top > .avatar {
    width: 120px;
    height: 120px;

    min-width: 120px;

    border-radius: 170px;
    overflow: hidden;
}

.user-profile-container > .user-profile > .user-profile-content > .user-profile-card > .top > .avatar > img {
    width: 120px;
    height: 120px;
    min-width: 120px;
    object-fit: cover;
}

.user-profile-container > .user-profile > .user-profile-content > .user-profile-card > .top > .content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
    width: 100%;
    overflow: hidden;
    box-sizing: border-box;
}

.user-profile-container > .user-profile > .user-profile-content > .user-profile-card > .top > .content > .name {
    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 32px;
    width: 100%;

    color: #222222;
}

.user-profile-container > .user-profile > .user-profile-content > .user-profile-card > .top > .content > .reviews-count {
    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;

    color: #C1C1C1;
}

.user-profile-container > .user-profile > .user-profile-content > .user-profile-card > .buttons {
    display: flex;
    flex-flow: column;
    width: 100%;
    box-sizing: border-box;
    gap: 12px;
}

.user-profile-container > .user-profile > .user-profile-content > .user-profile-card > .buttons > .button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 16px;
    gap: 8px;

    background: #EEEEEE;
    border-radius: 12px;

    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;

    color: #222222;

    width: 100%;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    box-sizing: border-box;
}

.user-profile-container > .user-profile > .user-profile-content > .user-profile-card > .buttons > .blue {
    background: #139CFF;
    color: #ffffff;
}

.user-profile-container > .user-profile > .user-profile-content > .user-profile-card > .buttons > .button:hover {
    opacity: 0.7;
}

.user-profile-container > .user-profile > .user-profile-content > .user-reviews {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
    gap: 16px;

    width: 100%;

    background: #FFFFFF;
    box-shadow: 0px -6px 6px rgba(0, 0, 0, 0.01), 0px 12px 26px rgba(0, 0, 0, 0.05);
    border-radius: 24px;

    max-width: 768px;
}

.user-profile-container > .user-profile > .user-profile-content > .user-reviews > .title-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
}

.user-profile-container > .user-profile > .user-profile-content > .user-reviews > .title-container > .title {
    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 32px;

    color: #222222;
}

.user-profile-container > .user-profile > .user-profile-content > .user-reviews > .title-container > .count {
    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 32px;

    color: #C1C1C1;
}

.user-profile-container > .user-profile > .user-profile-content > .user-reviews > .reviews {
    display: flex;
    flex-flow: column;
    width: 100%;
}


.user-profile-modal {
    display: flex;
    flex-flow: column;
    gap: 24px;
}

.user-profile-modal > .name-input {
    display: flex;
    flex-flow: column;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;

    color: #C1C1C1;

    width: 100%;
    gap: 4px;
}

.user-profile-modal > .name-input > .name-input-label {
    font-family: 'SF Pro Text', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: rgba(4, 4, 19, 0.55);
}

.user-profile-modal > .name-input > input {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;

    color: #000000;
    width: 100%;

    box-sizing: border-box;

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 6px 12px;
    gap: 8px;

    height: 48px;

    border: 1px solid #C1C1C1;
    border-radius: 12px;

    outline: none;
}

.user-profile-modal > .buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 16px;
}

.user-profile-modal > .buttons > .button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 16px;
    gap: 8px;

    width: 100%;
    background: #EEEEEE;
    border-radius: 12px;

    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    transition: all 0.07s ease-in-out;
    cursor: pointer;
}

.user-profile-modal > .buttons > .button:hover {
    opacity: 0.7;
}

.user-profile-modal > .buttons > .button:active {
    opacity: 0.5;
}


.user-profile-modal > .buttons > .blue {
    background: #139CFF;
    color: #FFFFFF;
}

.user-profile-modal > .error {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;

    color: #F72E2E;
}

.user-profile-modal > .buttons > .success {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 16px;
    gap: 8px;

    background: linear-gradient(87.77deg, rgba(140, 198, 63, 0) 26.7%, #8CC63F 98.53%), #22B573;
    border-radius: 12px;
    width: 80px;
}

.user-profile-modal > .buttons > .label {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;

    color: #C1C1C1;
}

@media (max-width: 768px) {
    .user-profile-container {
        margin: 72px 12px 72px;
    }

    .user-profile-container > .user-profile > .user-profile-content {
        flex-flow: column;
    }

    .user-profile-container > .user-profile > .user-profile-content > .user-profile-card {
        width: 100%;
        box-sizing: border-box;
    }

    .user-profile-container > .user-profile > .user-profile-content > .user-reviews {
        width: 100%;
        box-sizing: border-box;
    }
}