.modal-overlay {
    /*position: absolute; !* Изменено с fixed на absolute *!*/
    /*top: 0;*/
    /*left: 0;*/
    /*width: 100%;*/
    /*min-height: 100%; !* Используем min-height вместо height *!*/
    /*background-color: rgba(0, 0, 0, 0.5);*/
    /*z-index: 1000;*/

    position: fixed;
    z-index: 1300;
    inset: 0px;
}

.modal-backdrop {
    position: fixed;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    inset: 0px;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-tap-highlight-color: transparent;
    z-index: -1;
    opacity: 1;
    transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1);
}

.modal-backdrop-blur {
    position: fixed;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    inset: 0px;
    background-color: rgba(0, 0, 0, 0.7);
    -webkit-tap-highlight-color: transparent;
    z-index: -1;
    opacity: 1;
    transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1);
    /*-webkit-filter: blur(25px);*/
    /*-moz-filter: blur(25px);*/
    /*-o-filter: blur(25px);*/
    /*-ms-filter: blur(25px);*/
    /*filter: blur(25px);*/
    backdrop-filter: blur(25px);
}

.modal-content {
    /*background-color: #fff;*/
    /*padding: 20px;*/
    /*position: relative;*/
    /*border-radius: 8px;*/
    /*width: 90%;*/
    /*max-width: 500px;*/
    /*margin: 50px auto; !* Центрируем модальное окно и добавляем отступы сверху и снизу *!*/
    /*box-sizing: border-box;*/
    /*outline: none;*/

    height: 100%;
    outline: 0px;
    overflow: hidden auto;
    text-align: center;
    opacity: 1;
    transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1);
}

.modal-content-entry {
    color: rgba(0, 0, 0, 0.87);
    position: relative;
    overflow-y: auto;
    display: inline-block;
    vertical-align: middle;
    text-align: left;
    max-width: 500px;
    width: 100%;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1);
    margin: 32px;
    box-sizing: border-box;
    min-height: 240px;

    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
    gap: 24px;

    background: #FFFFFF;
    box-shadow: 0px -6px 6px rgba(0, 0, 0, 0.01), 0px 12px 26px rgba(0, 0, 0, 0.05);
    border-radius: 24px;
}

.modal-close {
    position: absolute;
    top: 10px;
    right: 15px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
}

.modal-hidden {
    position: absolute;
    opacity: 0;
}

.modal-component {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
    gap: 24px;

    box-shadow: 0px -6px 6px rgba(0, 0, 0, 0.01), 0px 12px 26px rgba(0, 0, 0, 0.05);
    border-radius: 24px;

}

.modal-overlay {
    opacity: 0;
    animation: fadeIn 0.3s forwards;
}

@keyframes fadeIn {
    to {
        opacity: 1;
    }
}

.modal-content {
    transform: translateY(-20px);
    animation: slideDown 0.3s forwards;
}

@keyframes slideDown {
    to {
        transform: translateY(0);
    }
}

@media (max-width: 600px) {
    .modal-content-entry {
        max-width: initial;
        width: calc(100% - 64px);
        box-sizing: border-box;
    }
}