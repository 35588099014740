.business-container {
    background: black;
    min-height: 100vh;
    padding-bottom: 120px;
}

.business-container > .header {
    width: 100%;

    box-sizing: border-box;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 16px 24px;
    gap: 36px;

    background: #000000;
    border-bottom: 1px solid #262626;

    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999;
}

.business-container > .header > .logo-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 12px;
    color: #F4F4F9;

    transition: all 0.15s ease-in-out;
    cursor: pointer;
}

.business-container > .header > .logo-container:hover {
    opacity: 0.75;
}

.business-container > .header > .logo-container:active {
    opacity: 0.5;
}

.business-container > .header > .logo-container > .cross {
    padding-left: 12px;
    padding-right: 12px;
}

.business-container > .header > .logo-container > .text {
    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: #F4F4F9;
}

.business-container > .header > .buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;

    color: #F4F4F9;
}

.business-container > .header > .buttons > .button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 16px 24px;
    gap: 10px;

    background: #ECEFF1;
    border-radius: 12px;

    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 590;
    font-size: 18px;
    line-height: 20px;
    color: #000000;

    transition: all 0.15s ease-in-out;
    cursor: pointer;
}

.business-container > .header > .buttons > .button:hover {
    opacity: 0.75;
}

.business-container > .header > .buttons > .button:active {
    opacity: 0.5;
}

.business-container > .header > .buttons > .gray {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 10px 16px;
    gap: 4px;

    background: #262626;
    border-radius: 12px;
}

.business-container > .header > .buttons > .gray > .span {
    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 590;
    font-size: 11px;
    line-height: 12px;
    color: #CFD2D3;
}

.business-container > .header > .buttons > .gray > .phone {
    display: flex;

    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 590;
    font-size: 14px;
    line-height: 16px;

    color: #F4F4F9;
}

.business-container > .header > .buttons > .gray > .phone > svg {
    margin-right: 4px;
}

.business-container > .content {
    display: flex;
    justify-content: center;

    padding-top: 85px;

    margin: 48px 0;
    width: 100%;
    box-sizing: border-box;
}

.business-container > .content > .entry {
    display: flex;
    flex-flow: column;
    gap: 120px;

    width: 1248px;
    box-sizing: border-box;
}


.business-container > .content > .entry > .block-1 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 84px;
}

.business-container > .content > .entry > .block-1 > .left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    color: #F4F4F9;
}

.business-container > .content > .entry > .block-1 > .left > .title {
    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 64px;
    line-height: 66px;

    color: #F4F4F9;
}

.business-container > .content > .entry > .block-1 > .left > .description {
    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;

    color: #F4F4F9;
}

.business-container > .content > .entry > .block-1 > .left > .buttons {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;
}

.business-container > .content > .entry > .block-1 > .left > .buttons > .button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 24px 32px;
    gap: 10px;

    background: #262626;
    border-radius: 24px;

    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 590;
    font-size: 24px;
    line-height: 24px;
    color: #F4F4F9;

    transition: all 0.15s ease-in-out;
    cursor: pointer;
}

.business-container > .content > .entry > .block-1 > .left > .buttons > .main {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 24px 32px;
    gap: 10px;

    background: #EF7C00;
    border-radius: 24px;

    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 590;
    font-size: 24px;
    line-height: 24px;
    color: #F4F4F9;
}

.business-container > .content > .entry > .block-1 > .left > .buttons > .button:hover {
    opacity: 0.75;
}

.business-container > .content > .entry > .block-1 > .left > .buttons > .button:active {
    opacity: 0.5;
}

.business-container > .content > .entry > .block-1 > .right {

}

.business-container > .content > .entry > .block-1 > .right > img {
    width: 400px;
    height: 400px;

    border-radius: 60px;
}


.business-container > .content > .entry > .block-2 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 32px;
}

.business-container > .content > .entry > .block-2 > .top {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 24px;
}

.business-container > .content > .entry > .block-2 > .top > .title {
    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 36px;

    color: #F4F4F9;
}

.business-container > .content > .entry > .block-2 > .top > .description {
    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;

    color: #F4F4F9;
}

.business-container > .content > .entry > .block-2 > .bottom {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;
}

.business-container > .content > .entry > .block-2 > .bottom > .card {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
    padding: 12px 12px 0px;

    background: #262626;
    border-radius: 24px;
    height: 268px;
    width: 220px;
    box-sizing: border-box;
}

.business-container > .content > .entry > .block-2 > .bottom > .card > img {
    width: 196px;
    height: 196px;

    border-radius: 12px;
}

.business-container > .content > .entry > .block-2 > .bottom > .card > .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 16px;
    gap: 24px;

    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: #F4F4F9;
    width: 100%;
    box-sizing: border-box;
}

.business-container > .content > .entry > .block-2 > .bottom > .card > .text > span {
    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: #EF7C00;
    box-sizing: border-box;
}

.business-container > .content > .entry > .block-2 > .bottom > .card > .description {
    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;

    color: #F4F4F9;
}

.business-container > .content > .entry > .block-2 > .bottom > .card > .title {
    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 590;
    font-size: 24px;
    line-height: 24px;

    color: #F4F4F9;
}

.business-container > .content > .entry > .block-2 > .bottom > .last {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 32px;
    gap: 16px;

    width: 304px;
    height: 268px;
}


.business-container > .content > .entry > .block-2 > .bottom-super {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;


    width: 100%;
    box-sizing: border-box;
}

.business-container > .content > .entry > .block-2 > .bottom-super > .label {
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 24px 32px;
    gap: 16px;

    width: 100%;

    border: 2px solid #262626;
    border-radius: 24px;

    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 590;
    font-size: 24px;
    line-height: 24px;
    color: #F4F4F9;
}


.business-container > .content > .entry > .block-3 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 24px;
    width: 100%;
}

.business-container > .content > .entry > .block-3 > .title {
    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 36px;
    text-align: center;

    color: #F4F4F9;
    width: 100%;
}

.business-container > .content > .entry > .block-3 > .tags {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 8px 0px;
    gap: 24px;
    width: 100%;
}

.business-container > .content > .entry > .block-3 > .tags > .tag {
    box-sizing: border-box;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 24px;
    gap: 22px;

    background: #000000;
    border: 2px solid #262626;
    border-radius: 16px;

    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;

    height: 52px;

    color: #ECEFF1;

    transition: all 0.6s ease-in-out;
}

.business-container > .content > .entry > .block-3 > .tags > .hover {
    background: #262626;
    box-shadow: 0px 10.2114px 40.8455px rgba(255, 255, 255, 0.15), inset 0px 0px 12.8919px rgba(0, 0, 0, 0.25);
    border-radius: 20.4228px;
    transform: var(--rotate-deg);
}


.business-container > .content > .entry > .block-4 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;
    width: 100%;
}

.business-container > .content > .entry > .block-4 > .title {
    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 36px;
    color: #F4F4F9;
    margin-bottom: 16px;
}

.business-container > .content > .entry > .block-4 > .cards-top {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;
}

.business-container > .content > .entry > .block-4 > .cards-top > .card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
    gap: 16px;

    width: 300px;
    height: 208px;
    box-sizing: border-box;

    background: #262626;
    border-radius: 24px;

    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    color: #F4F4F9;
}

.business-container > .content > .entry > .block-4 > .cards-bottom {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    gap: 16px;
}

.business-container > .content > .entry > .block-4 > .cards-bottom > .card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
    gap: 16px;

    width: 405.33px;
    height: 176px;
    box-sizing: border-box;

    background: #262626;
    border-radius: 24px;

    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    color: #F4F4F9;
}


.business-container > .content > .entry > .block-5 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;
    width: 100%;

    box-sizing: border-box;
    border: 2px solid #262626;
    border-radius: 36px;
}

.business-container > .content > .entry > .block-5 > .left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 36px;
    gap: 48px;
}

.business-container > .content > .entry > .block-5 > .left > .title {
    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 36px;
    color: #F4F4F9;
}

.business-container > .content > .entry > .block-5 > .left > .description {
    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: #F4F4F9;
}

.business-container > .content > .entry > .block-5 > .left > .button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 24px 32px;
    gap: 10px;

    background: #EF7C00;
    border-radius: 24px;

    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 590;
    font-size: 24px;
    line-height: 24px;
    color: #F4F4F9;
    transition: all 0.15s ease-in-out;
    cursor: pointer;
}

.business-container > .content > .entry > .block-5 > .left > .button:hover {
    opacity: 0.75;
}

.business-container > .content > .entry > .block-5 > .left > .button:active {
    opacity: 0.5;
}

.business-container > .content > .entry > .block-5 > .right {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 12px;
    gap: 16px;
}

.business-container > .content > .entry > .block-5 > .right > .card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 24px;
    gap: 8px;
    isolation: isolate;

    width: 286px;
    height: 304px;

    background: #000000;
    border-radius: 24px;
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
}

.business-container > .content > .entry > .block-5 > .right > .card > .circle {
    position: relative;
    width: 157.96px;
    height: 140.26px;
    left: -32px;
    top: 89.82px;

    background: #EF7C00;
    filter: blur(108.311px);
    border-radius: 251.156px;
    transform: matrix(-0.93, -0.37, 0.39, -0.92, 0, 0);
}

.business-container > .content > .entry > .block-5 > .right > .middle > .circle {
    background: #28FFE5
}

.business-container > .content > .entry > .block-5 > .right > .card > .description {
    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    color: #F4F4F9;
}

.business-container > .content > .entry > .block-5 > .right > .card > .price {
    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 48px;
    color: #F4F4F9;
}

.business-container > .content > .entry > .block-5 > .right > .card > .delay {
    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: #F4F4F9;
}


.business-container > .content > .entry > .block-6 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 32px;
}

.business-container > .content > .entry > .block-6 > .title {
    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 36px;

    color: #F4F4F9;
}

.business-container > .content > .entry > .block-6 > .card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 32px;
    gap: 16px;

    background: #262626;
    border-radius: 24px;

    width: 100%;
}

.business-container > .content > .entry > .block-6 > .card > .description {
    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: #F4F4F9;
}

.business-container > .content > .entry > .block-6 > .card > .price-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 16px;
}

.business-container > .content > .entry > .block-6 > .card > .price-container > .price {
    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 48px;
    color: #F4F4F9;
}

.business-container > .content > .entry > .block-6 > .card > .price-container > .per {
    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: #84878A;
}


.business-container > .content > .entry > .block-7 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 84px;
}

.business-container > .content > .entry > .block-7 > .columns {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 84px;
}

.business-container > .content > .entry > .block-7 > .columns > .column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 36px;

    width: 582px;
}

.business-container > .content > .entry > .block-7 > .columns > .column > .title {
    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 36px;
    color: #F4F4F9;
}

.business-container > .content > .entry > .block-7 > .columns > .column > .questions {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    gap: 16px;
}

.business-container > .content > .entry > .block-7 > .columns > .column > .questions > .question {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 8px 4px 12px;

    background: #262626;
    border-radius: 24px;

    box-sizing: border-box;

    width: 100%;
    height: fit-content;

    transition: all 0.5s ease-in-out;
    cursor: pointer;

    overflow: hidden;
}

.business-container > .content > .entry > .block-7 > .columns > .column > .questions > .question > .head {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 14px 24px;
    gap: 12px;

    width: 100%;

    box-sizing: border-box;
}

.business-container > .content > .entry > .block-7 > .columns > .column > .questions > .question > .head > .title {
    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: #F4F4F9;
}

.business-container > .content > .entry > .block-7 > .columns > .column > .questions > .question > .head > .arrow {
    margin-left: auto;

    transition: all 0.5s ease-in-out;
}

.business-container > .content > .entry > .block-7 > .columns > .column > .questions > .question > .description {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 24px;
    gap: 12px;

    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;

    color: #F4F4F9;

    visibility: hidden;
    overflow: hidden;

    transition: height 0.5s cubic-bezier(0.4, 0, 0.2, 1) 0ms;

    height: 0px;
    min-height: 0px;
}

.business-container > .content > .entry > .block-7 > .columns > .column > .questions > .active > .description {
    visibility: visible;

    min-height: 0;
    height: auto;
    transition-duration: 0.5s;
}

.business-container > .content > .entry > .block-7 > .columns > .column > .questions > .active > .head > .arrow {
    transform: rotate(180deg);
}

.business-container > .content > .entry > .block-7 > .columns > .column > .card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 32px;
    gap: 16px;

    width: 100%;

    background: #262626;
    border-radius: 36px;
}

.business-container > .content > .entry > .block-7 > .columns > .column > .card > .title {
    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 36px;

    color: #F4F4F9;
}

.business-container > .content > .entry > .block-7 > .columns > .column > .card > .field {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 8px 0px;
    gap: 8px;
}

.business-container > .content > .entry > .block-7 > .columns > .column > .card > .field > a {
    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 36px;
    color: #F4F4F9;

    transition: all 0.2s ease-in-out;
    cursor: pointer;
}

.business-container > .content > .entry > .block-7 > .columns > .column > .card > .field > a:hover {
    opacity: 0.75;
}

.business-container > .content > .entry > .block-7 > .columns > .column > .card > .field > a:active {
    opacity: 0.5;
}

.business-container > .content > .entry > .block-7 > .columns > .column > .card > .field > .label {
    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: #F4F4F9;
}


.business-container > .content > .entry > .block-8 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 84px;

    width: 100%;
}

.business-container > .content > .entry > .block-8 > .label {
    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    text-align: center;

    color: #84878A;
    width: 100%;
}


.business-modal-overlay > .modal-content > .modal-content-entry {
    box-sizing: border-box;

    background: #000000;
    border: 2px solid #EF7C00;
    border-radius: 36px;
    min-height: initial;
}

.business-modal-overlay > .modal-content > .modal-content-entry > .modal-close {
    color: #F4F4F9;
}

.business-modal {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;

}

.business-modal > .title {
    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 36px;
    color: #F4F4F9;
}

.business-modal > .description {
    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: #F4F4F9;
}

.business-modal > .inputs {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    /*gap: 16px;*/
    width: 100%;

    margin-top: 16px;
}


.business-modal > .inputs > span {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #CFD2D3;
    margin-bottom: 2px
}

.business-modal > .inputs > .input {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;

    color: #C1C1C1;

    width: 100%;
    box-sizing: border-box;

    height: 48px;

    outline: none;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 6px 12px;

    background: #262626;
    border-radius: 12px;

    margin-bottom: 16px;
}

.business-modal > .inputs > .input > input {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;

    color: #C1C1C1;
    width: 100%;

    box-sizing: border-box;

    height: 48px;
    border: none;
    background: transparent;
    outline: none;
}

.business-modal > .button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 24px;
    gap: 10px;

    background: #ECEFF1;
    border-radius: 12px;

    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 590;
    font-size: 18px;
    line-height: 20px;

    color: #000000;

    width: 100%;
    box-sizing: border-box;

    transition: all 0.2s ease-in-out;
    cursor: pointer;
}

.business-modal > .button:hover {
    opacity: 0.75;
}

.business-modal > .button:active {
    opacity: 0.5;
}


@media (max-width: 1248px) {
    .business-container > .header {
        padding: 8px 12px;
        gap: 36px;
    }

    .business-container > .header > .logo-container > .text {
        display: none;
    }

    .business-container > .header > .buttons > .request {
        left: 50%;
        bottom: 40px;
        position: fixed;
        transform: translateX(-50%);
        z-index: 999;
        white-space: nowrap;
    }

    .business-container > .content > .entry {
        width: 100%;
        padding: 0 16px;
        box-sizing: border-box;
        gap: 48px;
    }

    .business-container > .content > .entry > .block-1 {
        flex-flow: column;
        width: 100%;
        box-sizing: border-box;
        gap: 16px;
    }

    .business-container > .content > .entry > .block-1 > .left {
        width: 100%;
        box-sizing: border-box;
    }

    .business-container > .content > .entry > .block-1 > .left > .title {
        font-style: normal;
        font-weight: 600;
        font-size: 36px;
        line-height: 36px;

        color: #F4F4F9;
    }

    .business-container > .content > .entry > .block-1 > .left > .description {
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 28px;

        color: #F4F4F9;
    }

    .business-container > .content > .entry > .block-1 > .left > .buttons {
        flex-flow: column;
        width: 100%;
        box-sizing: border-box;
    }

    .business-container > .content > .entry > .block-1 > .left > .buttons > .button {
        width: 100%;
        box-sizing: border-box;
    }

    .business-container > .content > .entry > .block-1 > .right {
        width: 100%;
        box-sizing: border-box;
    }

    .business-container > .content > .entry > .block-1 > .right > img {
        width: 100%;
        height: 336px;
        object-fit: cover;
        box-sizing: border-box;
    }

    .business-container > .content > .entry > .block-2 > .top > .title {
        font-style: normal;
        font-weight: 600;
        font-size: 36px;
        line-height: 36px;

        color: #F4F4F9;
    }

    .business-container > .content > .entry > .block-2 > .top > .description {
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 28px;

        color: #F4F4F9;
    }

    .business-container > .content > .entry > .block-2 > .bottom {
        overflow-y: scroll;

        width: 100%;
    }

    .business-container > .content > .entry > .block-2 > .bottom > .last {
        min-width: 208px;
        flex-wrap: nowrap;
    }

    .business-container > .content > .entry > .block-2 > .bottom > .card > .description {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 28px;

        color: #F4F4F9;
    }

    .business-container > .content > .entry > .block-2 > .bottom > .card > .title {
        font-style: normal;
        font-weight: 590;
        font-size: 20px;
        line-height: 24px;
        color: #FFFFFF;
    }

    .business-container > .content > .entry > .block-2 > .bottom-super {

    }

    .business-container > .content > .entry > .block-2 > .bottom-super > .label {
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 26px;
        color: #FFFFFF;

        padding: 24px;
    }

    .business-container > .content > .entry > .block-3 > .tags {
        gap: 16px;
    }

    .business-container > .content > .entry > .block-3 > .tags > .tag {
        box-sizing: border-box;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 8px 16px;
        gap: 22px;

        height: 29px;

        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 13px;
        color: #ECEFF1;
    }

    .business-container > .content > .entry > .block-3 > .tags > .tag > svg {
        min-height: 14px;
        min-width: 14px;
    }

    .business-container > .content > .entry > .block-4 > .cards-top {
        width: 100%;
        box-sizing: border-box;
    }

    .business-container > .content > .entry > .block-4 > .cards-top > .card {
        width: 100%;
        height: fit-content;
        box-sizing: border-box;

        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 28px;

        color: #F4F4F9;
    }

    .business-container > .content > .entry > .block-4 > .cards-bottom {
        width: 100%;
        box-sizing: border-box;
    }

    .business-container > .content > .entry > .block-4 > .cards-bottom > .card {
        width: 100%;
        height: fit-content;
        box-sizing: border-box;

        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 28px;

        color: #F4F4F9;
    }

    .business-container > .content > .entry > .block-5 {
        flex-flow: column;
    }

    .business-container > .content > .entry > .block-5 > .left {
        padding: 24px;
        gap: 24px;
        width: 100%;
        box-sizing: border-box;
    }

    .business-container > .content > .entry > .block-5 > .left > .button {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 24px 32px;
        gap: 10px;

        background: #EF7C00;
        border-radius: 24px;

        font-style: normal;
        font-weight: 590;
        font-size: 24px;
        line-height: 24px;

        color: #FFFFFF;

        white-space: nowrap;

        width: 100%;
        box-sizing: border-box;
    }

    .business-container > .content > .entry > .block-5 > .right {
        width: 100%;
        box-sizing: border-box;
    }

    .business-container > .content > .entry > .block-5 > .right > .card {
        width: 100%;

        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 24px;
        gap: 8px;
        isolation: isolate;

        height: 180px;

        background: #000000;
        border-radius: 24px;
    }

    .business-container > .content > .entry > .block-5 > .right > .card > .circle {
        position: absolute;
    }

    .business-container > .content > .entry > .block-6 > .card {
        width: 100%;
        box-sizing: border-box;
    }

    .business-container > .content > .entry > .block-6 > .card > .price-container > .per {
        display: none;
    }

    .business-container > .content > .entry > .block-7 > .columns {
        flex-flow: column;
        width: 100%;
        box-sizing: border-box;
    }

    .business-container > .content > .entry > .block-7 > .columns > .column > .card {
        width: 100%;
        box-sizing: border-box;
    }

    .business-container > .content > .entry > .block-7 > .columns > .column {
        width: 100%;
    }
}


@media (max-width: 600px) {
    .business-container > .content > .entry > .block-5 > .right {
        flex-wrap: wrap;
        width: 100%;
        box-sizing: border-box;
    }

    .business-container > .content > .entry > .block-5 > .right > .card {
        width: 100%;

        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 24px;
        gap: 8px;
        isolation: isolate;

        height: 180px;

        background: #000000;
        border-radius: 24px;
    }

    .business-container > .content > .entry > .block-5 > .right > .card > .circle {
        position: absolute;
    }
}